.pricingSection {
  margin-top: 100px !important;
}

.pricingSection-disc {
  color: #74767a;
  font-size: 24px;
  text-align: center;
}

.plan-cards-container {
  width: 80%;
  margin-inline: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;
}
