.digitalizeSection {
  margin-top: 100px !important;
}

.digitalize-title {
  width: 60%;
  margin: auto;
  margin-bottom: 70px;
}

.digitalize-background {
  background-image: url("../../../../assets/images/digitalize.png");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  display: inline-block;
  padding: 10px 15px;
}

.digital-cards {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 90%;
  margin: 30px auto;
}

.digital-section-card {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 31%;
}

.digital-section-card img {
  height: 90px;
  width: auto;
  margin-bottom: 20px;
}

.digital-section-card h1 {
  font-weight: 700;
  color: #111827;
  margin-bottom: 20px;
  font-size: 2rem;
}

.digital-section-card p {
  color: #4b5563;
  text-align: center;
  font-size: 1.5rem;
}
