.referral-mainHeaders {
  margin-top: 100px !important;
}

.referral-mainHeader-1 {
  text-transform: capitalize;
}

.referral-mainHeader-2 {
  width: 65%;
  margin-inline: auto;
  margin-top: 100px;
  text-transform: capitalize;
}
