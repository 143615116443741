.landingPageFooter {
  padding-top: 40px;
  padding-bottom: 60px;
  background-color: #f3f4f6;
}

.footer-shortcuts {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

.footer-vertical-menu {
  width: 20%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.hollydesk-profile-link {
  margin-top: 10px;
  margin-inline-end: 5px;
  cursor: pointer;
}

.footer-vertical-menu h3 {
  font-weight: 600;
}

.footer-vertical-menu a {
  color: black;
}

.footer-vertical-menu a:hover {
  color: #06d6a0;
}

.copyright-item {
  margin-inline-end: 20px;
}

.hollydesk-copyright {
  font-weight: 600;
}

.copyright-hovered {
  cursor: pointer;
}

.footer-bottom {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.footer-copyrights {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.footer-lang {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}
