.article-card {
  width: 30%;
  aspect-ratio: 4/3;
  margin-bottom: 50px;
  margin-inline: 1.66%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  box-sizing: border-box;
  border: 1px solid transparent;
  padding: 10px;
  border-radius: 15px;
  cursor: pointer;
}

.article-card:hover {
  border: 1px solid #d8dae5;
  padding: 10px;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
}

.article-card img {
  border-radius: 10px;
  width: 100%;
}

.articleCard-label {
  display: inline-block;
  padding: 5px 15px;
  margin-top: 10px;
  margin-bottom: 20px;
  border-radius: 30px;
  color: #f9fafb;
  font-weight: 600;
}

.articleCard-title {
  cursor: pointer;
  font-weight: 700;
  font-size: 1.5rem;
  line-height: 1.8rem;
  color: #111827;
  margin-bottom: 10px !important;
}

.articleCard-title:hover {
  text-decoration: underline;
  color: #111827;
}

.articleCard-publish {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: auto;
}

.articleCard-author {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.articleCard-authorName {
  margin-bottom: 0px;
  font-weight: 600;
  color: #4b5563;
  margin-inline-start: 10px;
  font-size: 1.2rem;
}
