.nav-bar {
  max-width: 1600px;
  padding-inline: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 60px;
}

nav {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.nav-bar-logo {
  margin-inline-end: 30px;
}

.nav-bar-item {
  text-decoration: none;
  color: black;
  margin-inline-end: 20px;
  font-weight: 600;
  transition: color 0.2s;
  cursor: pointer;
}

.nav-bar-item:hover,
.nav-bar-item-active:hover {
  color: #06d6a0;
}

.nav-bar-item-active {
  text-decoration: none;
  color: #06d6a0;
  margin-inline-end: 20px;
  font-weight: 600;
}

.nav-bar-actions {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.lang-option {
  font-weight: 600;
  display: flex;
  align-items: center;
}

.lang-option img {
  height: 25px;
  width: auto;
}

.lang-name {
  margin-inline: 5px;
  font-weight: 600;
}

.lang-icon {
  font-weight: 600;
}

.mobile-menu {
  display: none;
}

.ant-menu-submenu-title,
.ant-menu-submenu,
.ant-menu-sub,
.ant-menu-item,
.ant-menu-submenu-title,
.ant-drawer-wrapper-body {
  background-color: #ffffff !important;
  padding: 0px !important;
}

.ant-menu-inline .ant-menu-item::after {
  border: none !important;
}
