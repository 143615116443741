.calc-screen {
  width: 49%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  background-color: #eef9f3;
  border-radius: 10px;
}

.calc-screen-company {
  width: 100%;
  padding-inline: 30px;
  font-size: 1.5rem;
  margin-top: 20px;
}

.calc-sreen-qrtr {
  width: 50%;
  aspect-ratio: 1/0.8;
  padding: 30px;
  border: 2px solid transparent;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
}

.qrtr-right {
  border-right: 2px solid #56c288;
}

.qrtr-bottom {
  border-bottom: 2px solid #56c288;
}

.screen-title {
  font-size: 1.5rem;
  color: #111827;
}

.screen-value {
  color: #111827;
  font-size: 2rem;
  font-weight: 400;
}

.screen-unit {
  font-size: 18px;
  color: #4b5563;
}
