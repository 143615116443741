.featuresSection {
  margin-top: 100px !important;
}

.features-background {
  padding: 5px 0px;
  background-image: url("../../../../assets/images/features-background.png");
  background-size: cover;
}

.feature-element {
  width: 70%;
  margin: 30px auto;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-radius: 30px;
  padding: 25px;
}

.feature-element img {
  height: 400px;
  width: auto;
}

.feature-element-dialog {
  margin-inline: 30px;
}

.feature-element-name {
  font-weight: 600;
}

.feature-element h3 {
  font-weight: 700;
  font-size: 1.4rem;
}

.feature-element-content {
  color: #4b5563;
  margin-bottom: 30px !important;
}

.feature-element-dialog a {
  text-decoration: underline;
}

.feature-element-dialog a:hover {
  text-decoration: none;
}
