.trustedBy-wrapper {
  width: 100%;
  background-color: #f3f4f6;
  padding: 50px 0px;
  margin-top: 80px;
}

.trustedBy-section .featuredIn-logos img {
  margin-inline: 25px;
}

.companies-logos {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin-top: 30px;
}

.review-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 80px;
}

.review-wrapper-mobile {
  display: none;
}

.review {
  width: 45%;
}

.review h1 {
  font-weight: 600;
  font-size: 1.8rem;
}

.review-text {
  margin-bottom: 30px !important;
  color: #4b5563;
  font-weight: 400;
  font-size: 1.3rem;
}

.testimonials {
  width: 50%;
}

.reviewer {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.reviewer-info {
  margin-inline-start: 10px;
}

.reviewer-info h3 {
  margin-bottom: 0px;
}

.reviews-controls {
  font-size: 18px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-top: 20px;
  color: #111827;
}

.review-control {
  cursor: pointer;
  margin-inline: 10px;
}
