.knowMoreSection {
  margin-top: 150px !important;
  width: 75%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: #2dd4be23;
  border-radius: 30px;
  padding: 60px !important;
}

.know-more-content {
  width: 60%;
}

.knowMoreSection img {
  width: 30%;
  height: auto;
}

.knowMoreSection .know-more-content h2 {
  color: #0bb78a;
  font-size: 1.5rem;
  font-weight: 600;
}

.knowMoreSection .know-more-content h1 {
  color: #111827;
  font-size: 2rem;
  font-weight: 700;
}

.knowMoreSection .know-more-content p {
  color: #4b5563;
  font-size: 1.5rem;
  font-weight: 400;
  margin-bottom: 20px !important;
}
