.seccess-demo .seccessIMG {
  width: 35%;
  margin-inline-start: 32.5%;
  margin-top: 50px;
  margin-bottom: 80px;
}

.sucess-msg {
  font-weight: 700;
  color: #111827;
  font-size: 36px;
  text-align: center;
  width: 80%;
  margin-inline: auto;
}

.seccessBtn {
  width: 40%;
  margin: 60px 30%;
}
