.app-features-wrapper {
  width: 100%;
  background-image: url("../../../../assets/images/app-features-wrapper.png");
  background-size: cover;
  background-repeat: repeat-y;
}

.app-features-section {
  margin-top: 70px !important;
}

.app-features-title {
  text-align: start !important;
}

.app-features-disc {
  text-align: start;
  color: #4b5563;
}

.app-feature-element {
  margin: 100px auto;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-radius: 30px;
  padding: 30px;
}

.app-feature-element img {
  width: 50%;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
  border-radius: 30px;
}

.app-feature-element-dialog {
  margin-inline: 30px;
}

.app-feature-element-name {
  color: #56c288;
  font-weight: 600;
  font-size: 1.5rem;
}

.app-feature-element-title {
  color: #111827;
  font-size: 2rem;
  font-weight: 700;
  margin-bottom: 24px;
}

.app-feature-element-content {
  color: #4b5563;
  font-size: 24px;
}
