.loggin-banner-container {
  width: 50%;
  height: 100%;
  /* height: stretch; */
  /* display: flex; */
  /* align-items: center; */
  /* justify-content: center; */
  overflow: hidden;
}

.login-banner-pic {
  /* height: 100%; */
  height: calc(100vh - 60px);
  width: auto;
}
