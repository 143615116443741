.featuredIn {
  margin-top: 100px !important;
}

.featuredIn-logos {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  /* width: 70%; */
  margin-inline: auto;
  flex-wrap: nowrap;
  overflow-x: hidden;
}

.featuredIn-logos img {
  width: 200px;
  height: auto;
  margin-inline: 5px;
}

@keyframes moveLogos {
  from {
  }
}
