.pricingFAQSection {
  margin-top: 80px !important;
  max-width: 70% !important;
}

/* .FAQs-group {
  max-width: 70% !important;
  margin: auto !important;
} */

.FAQ-question {
  font-size: 1.3rem !important;
  font-weight: 600 !important;
}

.FAQ-answer {
  font-size: 1.1rem !important;
  color: #6b7280;
}
