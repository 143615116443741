#article-header {
  height: 60px;
  width: 100%;
  max-width: 1600px;
  background-color: white;
  position: fixed;
  padding-inline-end: 60px;
  top: -64px;
  z-index: 10;
  transition: all 0.5s;
}

#article-header div:last-child {
  margin-inline-end: 60px !important;
  max-width: 1600px;
}

.progress-container {
  width: 100%;
  height: 4px;
  background: #ccc;
}

.progress-bar {
  height: 4px;
  background: #04aa6d;
  width: 0%;
}

.article-header-content {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: auto;
}

.article-content {
  width: 70%;
}

.article-content h2 {
  margin-top: 30px;
}

.article-content h3 {
  margin-top: 30px;
}

.article-content .ql-video {
  width: 100%;
  height: 50vh;
}

.article-content p {
  margin-bottom: 1em !important;
}

.share-article {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-top: 50px;
  margin-bottom: 50px;
}

.share-article-line {
  font-weight: 600;
  color: #4b5563;
  font-size: 32px;
  margin-inline-end: 30px;
}

.share-article img {
  margin-inline-end: 10px;
}

.more-article-line {
  font-weight: 600;
  color: #4b5563;
  font-size: 32px;
  margin-inline-end: 30px;
  margin-bottom: 30px !important;
}
