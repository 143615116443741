.loginForm {
  width: 40%;
  /* height: 100%; */
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: flex-start;
}

.loginform-title {
  text-align: start !important;
}

.forget-pass-link {
  display: inline-block;
  width: 100%;
  text-align: end;
  color: #56c288;
  font-weight: 600;
  font-size: 1.3rem;
  margin-bottom: 60px;
}

.login-btn {
  width: 100%;
  margin-bottom: 50px;
}

.login-register {
  font-weight: 600;
  font-size: 1.3rem;
}

.login-register-link {
  color: #56c288;
}

.login-register-link:hover,
.forget-pass-link:hover {
  color: #56c288;
  text-decoration: underline;
}
