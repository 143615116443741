.demo-banner {
  margin-top: 80px !important;
  position: relative;
  padding: 30px;
}

.demo-banner-subtitle {
  font-size: 28px;
  color: #6b7280;
  text-align: center;
}

.demo-img-1 {
  position: absolute;
  top: -30px;
  left: 30px;
  height: 50px;
}

.demo-img-2 {
  position: absolute;
  top: -80px;
  right: 30px;
  /* height: 180px; */
}

.demo-img-3 {
  position: absolute;
  bottom: -40px;
  left: 100px;
  height: 50px;
}

.demo-img-4 {
  position: absolute;
  bottom: -120px;
  right: 30px;
  height: 200px;
}
