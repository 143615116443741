.loveHollyDeskSection {
  border-radius: 30px;
  background-color: #e5e7eb;
  padding: 80px;
  margin-top: 150px !important;
  margin-inline: auto !important;
}

.loveHollyDesk-title {
  text-align: start;
  margin-bottom: 30px;
}

.loveHollyDesk {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.reasons {
  width: 40%;
}

.loveHollyDesk img {
  width: 60%;
}

.reasons-element {
  display: block;
  margin-top: 25px;
  font-size: 20px;
  color: #111827;
}
