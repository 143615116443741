.stepsSection {
  margin-top: 50px !important;
  position: relative;
}

.stepsSection-title {
  width: 40%;
  margin-inline: auto;
  margin-bottom: 50px;
}

.step-element {
  width: 70%;
  margin: 80px auto;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-radius: 30px;
}

.step-element-dialog {
  width: 65%;
  margin-inline: 30px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
}

.step-index {
  display: block;
  margin-inline-end: 10px;
  font-size: 1.5rem;
  color: white;
  background-color: #56c288;
  height: 40px;
  width: 40px !important;
  line-height: 40px;
  text-align: center;
  border-radius: 50%;
  flex-shrink: 0;
}

.step-title {
  font-weight: 700;
  font-size: 1.4rem;
}

.step-content {
  color: #4b5563;
  font-size: 18px;
}

.step-element img {
  width: 35%;
  height: auto;
}

.steps-1-2-arrow {
  position: absolute;
  top: 25%;
  margin-inline-start: 42%;
  height: 12%;
}

.steps-2-3-arrow {
  position: absolute;
  top: 51%;
  left: 15%;
  height: 10%;
}

.steps-3-4-arrow {
  position: absolute;
  top: 74%;
  margin-inline-start: 41%;
  height: 12%;
}

.steps-1-2-arrow img,
.steps-2-3-arrow img,
.steps-3-4-arrow img {
  height: 100%;
}
