* {
  box-sizing: border-box;
}

.App {
  /* font-family: "Graphik", sans-serif; */
  /* font-family: "Almarai", sans-serif !important; */
  box-sizing: border-box !important;
  margin: auto;
  font-weight: normal;
  text-align: unset !important;
}

/* @font-face {
  font-family: Graphik;
  src: url(./assets/fonts/Graphik-Font-Family/GraphikBlack.otf)
      format("opentype"),
    url(./assets/fonts/Graphik-Font-Family/GraphikBold.otf) format("opentype"),
    url(./assets/fonts/Graphik-Font-Family/GraphikExtralight.otf)
      format("opentype"),
    url(./assets/fonts/Graphik-Font-Family/GraphikLight.otf) format("opentype"),
    url(./assets/fonts/Graphik-Font-Family/GraphikMedium.otf) format("opentype"),
    url(./assets/fonts/Graphik-Font-Family/GraphikRegular.otf)
      format("opentype"),
    url(./assets/fonts/Graphik-Font-Family/GraphikSemibold.otf)
      format("opentype"),
    url(./assets/fonts/Graphik-Font-Family/GraphikSuper.otf) format("opentype"),
    url(./assets/fonts/Graphik-Font-Family/GraphikThin.otf) format("opentype");
} */

/* __________GLOBAL_STYLING__________ */

.mainPage {
  max-width: 1600px;
  padding-inline: 30px;
  margin: auto;
}

.section-title {
  color: #1f2937;
  font-size: 2.3rem;
  text-align: center;
  font-weight: 700;
}

p {
  margin-bottom: 0px !important;
}

.ant-input {
  height: 42px;
  border-radius: 5px !important;
}

.ant-input-affix-wrapper {
  border-radius: 5px !important;
}

.ant-dropdown-menu {
  border-radius: 10px !important;
}

.ant-input-affix-wrapper-focused,
.ant-input:focus {
  border-color: #0bb78a !important;
  box-shadow: 0 0 0 2px rgba(11, 183, 138, 0.2) !important;
}

.ant-input-affix-wrapper {
  padding: 0px !important;
}

.ant-input-password {
  padding-inline: 11px !important;
}

.ant-tabs-tab + .ant-tabs-tab {
  margin: 0px !important;
  margin-inline-start: 32px !important;
}

.ant-dropdown-menu-title-content {
  display: flex;
  justify-content: flex-start;
}

.ant-menu-light .ant-menu-submenu-title:hover,
.ant-menu-item-selected,
.ant-menu-item-selected a,
.ant-menu-item-selected a:hover {
  color: #56c288 !important;
}

/* Hide number input arrows for Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Hide number input arrows for Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

/* __________ BUTTONS __________ */

.btn {
  padding: 0.6rem 1.5rem;
  cursor: pointer;
  border-radius: 5px;
  outline: none;
  box-shadow: none;
  font-weight: 600;
}

.btnPrimary,
.btnPrimary:hover {
  color: white;
  background-color: #1d2b36;
  border: 1px solid #1d2b36;
  transition: transform 0.3s;
  text-align: center;
}

.btnSecondary,
.btnSecondary:hover {
  color: #1d2b36;
  background-color: white;
  border: 1px solid #1d2b36;
  transition: transform 0.3s;
  text-align: center;
}

.btnPrimary:hover,
.btnSecondary:hover {
  transform: scale(1.03);
}

.btnGreenFill {
  color: #ffffff;
  background-color: #0bb78a;
  border: 1px solid #0bb78a;
  transition: all 0.3s;
}

.btnGreenFill:hover {
  color: #0bb78a;
  background-color: #ffffff;
}

.btnGreenFillLight {
  color: #268c55;
  background: rgba(85, 194, 136, 0.1);
  border: 1px solid rgba(85, 194, 136, 0.1);
  transition: all 0.3s;
}

.btnGreenFillLight:hover {
  border-color: #268c55;
}

/* __________ FORMS __________ */

.form-line-full {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 30px;
}

.form-line-half {
  width: 49%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
}

.form-element-full label,
.form-element-half label {
  font-weight: 400;
  font-size: 20px;
}

.form-element-full input,
.form-element-half input {
  height: 42px;
  font-size: 1.2rem;
}

.form-element-full {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 30px;
}

.form-element-half {
  width: 49%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.form-err {
  color: firebrick;
  margin-top: 5px;
}
