.masonry {
  margin-top: 100px !important;
}

.masonry-review {
  background-color: #f3f4f6;
  padding: 30px;
  border-radius: 7px;
  margin: 10px 10px;
  width: 600px;
}

.reviewsLogos {
  width: 100%;
}
