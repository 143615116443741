.plan-card {
  width: 31%;
  border-radius: 15px;
  padding: 25px 20px;
  margin: 20px 1%;
  text-align: start;
  position: relative;
  border: 1px solid #e5e7eb;
}

.plan-name {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  color: inherit;
  font-size: 20px;
}

.plan-price {
  font-weight: 700;
  font-size: 40px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: baseline;
}

.plan-card .month {
  font-size: 16px;
  color: #989898;
  margin-inline-start: 7px;
}

.plan-billTime {
  font-size: 14px;
  color: #4f4f4f;
  font-weight: 500;
  margin-bottom: 16px;
}

.plan-desc {
  font-weight: 500;
  font-size: 14px;
  color: #6b7280;
  margin-bottom: 16px !important;
}

.plan-card .benifit {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 10px;
}

.plan-sub-btn {
  width: 100%;
  margin: 25px auto;
}

.most-popular {
  width: fit-content;
  flex-direction: row;
  align-items: center;
  background-color: #f57f17;
  color: #fff;
  padding: 3px 7px;
  border-radius: 20px;
  font-size: 11px;
  margin-inline-start: 15px;
  margin-bottom: 0px;
}

.most-popular p {
  margin-bottom: 0px;
}

.name-save {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 0.5em;
}
