.lowerBanner-wrapper {
  width: 100%;
  background-color: #ffc555;
  margin-top: 100px;
  padding-top: 50px;
  padding-bottom: 50px;
}

.lowerBanner-background {
  color: #56c288;
  background-image: url("../../../../assets/images/lowerBanner-background.png");
  background-size: cover;
  padding: 5px 0px;
}

.lowerBanner-buttons {
  display: flex;
  justify-content: center;
  align-items: center;
}

.lowerBanner-buttons a {
  text-align: center;
  margin-top: 30px;
  margin-inline: 10px;
  width: 10rem;
}
