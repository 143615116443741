.knowMore-lowBanner {
  width: 100%;
  margin-top: 150px !important;
  margin-bottom: 100px !important;
  padding-bottom: 50px;
}

.lowerBanner-buttons {
  display: flex;
  justify-content: center;
  align-items: center;
}

.lowerBanner-buttons button {
  margin-top: 30px;
  margin-inline: 10px;
  width: 10rem;
}
