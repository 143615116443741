.landingPage-banner {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.landingPage-bannerContent {
  margin-top: 50px;
  width: 45%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.landingPage-bannerHeadLine {
  font-weight: 700;
  font-size: 3rem;
  color: #111827;
  text-align: start;
}

.landingPage-bannerBackGround {
  background-image: url("../../../../assets/images/landing-page-banner-background.png");
  background-size: cover;
}

.landingPage-bannerSubTitle {
  color: #6b7280;
  font-size: 1.5rem;
}

.landingPage-bannerbuttons button:first-of-type {
  margin-inline-end: 20px;
}

.landingPage-benifit {
  display: block;
  margin-top: 15px;
  font-size: 16px;
  color: #111827;
}

.landingPage-banner img {
  width: 50%;
  height: auto;
}
