.blog-form {
  width: 80%;
  margin-inline: auto;
  margin-top: 50px;
  background-color: #f4fdfc;
  border-radius: 30px;
  padding: 60px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.blog-form img {
  width: 30%;
  margin-inline-end: 30px;
}

.blog-form-content {
  width: 69%;
}

.blog-form-line1 {
  color: #0bb78a;
  font-size: 24px;
  font-weight: 600;
}

.blog-form-line2 {
  color: #111827;
  font-size: 32px;
  font-weight: 700;
}

.blog-form-inputGroup {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: stretch;
  margin-top: 20px;
  width: 80%;
}

.btnAction {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40%;
}
