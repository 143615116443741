.blog-section {
  margin-top: 50px !important;
}

.article-cards-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: stretch;
  margin-top: 30px;
}

.blog-section .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #56c288 !important;
}
.blog-section .ant-tabs-ink-bar {
  background: #56c288 !important;
}
