.calculationInputs {
  width: 49%;
}

.calculationInputs-title {
  text-align: start !important;
  margin-bottom: 40px;
}

.calculationInputs-desc {
  text-align: start;
  font-weight: 600;
  font-size: 2rem;
  color: #111827;
  margin-bottom: 60px !important;
}

.calc-start-btn {
  width: 30%;
  margin-top: 50px;
}

.question-range-group {
  margin: 50px auto;
}

.question-range-group p {
  color: #56c288;
  font-weight: 700;
}

.question-range-group h3 {
  font-size: 1.5rem;
  color: #111827;
  margin-bottom: 20px;
}

.range-group {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.calculator-slider {
  width: 80%;
}

.slider-value {
  display: inline-flex;
  width: 100px;
  height: 45px;
  background-color: #e5e7eb;
  font-weight: 500;
  font-size: 1.5rem;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  outline: none;
  box-shadow: none;
  border: none;
  text-align: center;
}

.calc-controls {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 50px;
}

.calculator-control {
  width: 30%;
}
