.section-referral-form {
  margin-top: 100px !important;
}

.referral-form-title {
  text-transform: capitalize;
  margin-bottom: 50px;
}

.referral-form {
  width: 70%;
  margin: auto;
}

.referral-form-btn {
  width: 30%;
  margin-inline: 35%;
  margin-top: 40px;
}
