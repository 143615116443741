.FAQs-section {
  margin-top: 100px !important;
  width: 70%;
}

.question-group-title {
  text-align: center;
  font-weight: 600;
  font-size: 1.5rem;
  margin: 50px auto;
}
