.mobile-menu-btn {
  width: 100% !important;
  margin-top: 20px;
}

.ant-drawer-content-wrapper {
  width: 100% !important;
}

.ant-menu-inline.ant-menu-root
  .ant-menu-submenu-title
  > .ant-menu-title-content,
.ant-menu-inline.ant-menu-root .ant-menu-item > .ant-menu-title-content {
  display: flex !important;
}

.ant-menu-submenu-expand-icon,
.ant-menu-submenu-arrow {
  position: relative !important;
  top: auto !important;
  /* right: unset !important; */
}
