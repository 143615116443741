.bannerArticle {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  aspect-ratio: 5/1.5;
  overflow: hidden;
  margin-bottom: 80px;
}

.articleCard-desc {
  margin-bottom: 30px;
}

.articleBanner-publish {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-top: auto;
}

.articleBanner-author {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 50%;
}

.bannerArticle-poster {
  width: 49%;
  height: auto;
  border-radius: 15px;
}

.bannerArticle-content {
  width: 49%;
}

.banner-article-btn {
  margin-top: 30px;
  width: 40%;
}
