.benefitsSection {
  margin-top: 150px !important;
}

.benefits-title {
  width: 100%;
  text-align: center;
  font-size: 32px !important;
  margin-bottom: 50px;
}
