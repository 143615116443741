@media (max-width: 600px) {
  /* ---------- GENERAL ---------- */
  .mainPage {
    padding-inline: 15px;
  }

  .section-title {
    font-size: 1.5rem;
  }

  .form-line-full {
    flex-direction: column;
  }

  .form-line-half {
    flex-direction: column;
    width: 100%;
    margin-bottom: 30px;
  }

  .form-element-half {
    width: 100%;
    margin-bottom: 30px;
  }

  .form-element-half:last-child {
    margin-bottom: 0px;
  }

  #article-header {
    display: none;
  }

  /* ---------- SECTIONS WIDTH ---------- */
  .landingPage-bannerbuttons,
  .featuredIn,
  .featuredIn-logos,
  .digitalize-title,
  .trustedBy-section,
  .companies-logos,
  .companies-logos img,
  .review,
  .testimonials,
  .read-all-reviews-btn,
  .lowerBanner-buttons a,
  .referral-mainHeader-2,
  .referral-form,
  .referral-form-btn,
  .FAQs-section,
  .knowMoreSection img,
  .know-more-content button,
  .bannerArticle-poster,
  .bannerArticle-content,
  .article-card,
  .stepsSection-title,
  .loveHollyDesk img,
  .reasons,
  .secondBanner-title,
  .pricingFAQSection,
  .calculationInputs,
  .calc-start-btn,
  .calc-screen,
  .banner-article-btn,
  .article-content,
  .blog-form,
  .blog-form-content,
  .blog-form-inputGroup {
    width: 100% !important;
  }

  /* ---------- NAV BAR ---------- */
  .nav-bar {
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
  }

  .nav-bar-items,
  .nav-bar-actions {
    display: none;
  }

  .mobile-menu {
    display: block;
  }

  /* ---------- HOME ---------- */
  .landingPage-banner img {
    display: none;
  }

  .landingPage-bannerContent {
    margin-top: 20px;
    width: 100%;
  }

  .landingPage-bannerHeadLine {
    font-size: 1.5rem;
  }

  .landingPage-bannerSubTitle {
    font-size: 1.2rem;
  }

  .landingPage-bannerbuttons:nth-child(3) {
    order: 2;
  }

  .landingPage-bannerbuttons button {
    display: block;
    width: 100%;
    margin-top: 20px;
  }

  .featuredIn-logos .featuredIn-animation {
    width: 31%;
  }

  .featuredIn-logos img {
    width: 150px !important;
  }

  .digital-cards {
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }

  .digital-section-card {
    width: 100%;
    margin-bottom: 30px;
  }

  .digital-section-card h1 {
    font-size: 1.3rem;
    margin-bottom: 10px;
  }

  .digital-section-card p {
    font-size: 1.1rem;
  }

  .feature-element {
    width: 100%;
    flex-direction: column !important;
  }

  .feature-element img {
    width: 100%;
    height: auto;
    margin-top: 20px;
  }

  .feature-element-dialog {
    margin-bottom: 20px;
  }

  .companies-logos .companies-logos-animate {
    width: 24%;
    height: auto;
  }

  .review-wrapper {
    display: none;
  }

  .review-wrapper-mobile {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: 30px;
  }

  .reviews-controls {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-bottom: 30px;
    font-size: 1.1rem;
  }

  .lowerBanner-buttons {
    flex-direction: column;
    width: 100%;
  }

  .footer-shortcuts {
    flex-wrap: wrap;
  }

  .footer-shortcuts > .footer-vertical-menu {
    width: 100%;
    margin-bottom: 20px;
  }

  .footer-shortcuts > .footer-vertical-menu ~ .footer-vertical-menu {
    width: 50%;
  }

  .footer-bottom {
    flex-direction: column;
  }

  .footer-copyrights {
    justify-content: space-between;
    width: 100%;
  }

  .copyright-item {
    margin-inline: 0px;
  }

  .footer-lang {
    width: 100%;
    justify-content: flex-start;
    margin-top: 20px;
  }

  /* ---------- FEATURES ---------- */

  .app-feature-element {
    flex-direction: column !important;
    padding: 15px;
    background: linear-gradient(
      0deg,
      rgb(249, 250, 251) 80%,
      white 20%
    ) !important;
    border-radius: 10px;
  }

  .app-feature-element img {
    width: 100%;
    border-radius: 10px;
    margin-bottom: 20px;
  }

  .app-feature-element-dialog {
    width: 100%;
    padding: 0px;
  }

  /* ---------- REFERRAL ---------- */

  .referral-form-btn {
    margin-inline: 0px;
    margin-top: 30px;
  }

  /* ---------- PRICING ---------- */

  .knowMoreSection {
    width: 90%;
    padding: 20px !important;
    border-radius: 15px;
    flex-direction: column-reverse;
  }

  .know-more-content {
    width: 100%;
    margin-top: 30px;
  }

  /* ---------- BLOG ---------- */

  .bannerArticle {
    flex-direction: column;
    overflow: auto;
    aspect-ratio: unset;
  }

  .articleCard-label {
    font-size: 12px;
    font-weight: normal;
  }

  .articleBanner-publish {
    justify-content: space-between;
  }

  .blog-form {
    flex-direction: column;
    padding: 15px;
  }

  .blog-form img {
    width: 70%;
    margin-inline: auto;
  }

  .blog-form-line1 {
    font-size: 14px;
  }

  .blog-form-line2 {
    font-size: 18px;
  }

  .share-article-line,
  .more-article-line {
    font-size: 24px;
  }

  .blog-form-inputGroup {
    flex-direction: column;
  }

  .blog-form-inputGroup .btnAction {
    width: 100%;
    margin-top: 30px !important;
  }

  /* ---------- HOW IT WORKS ---------- */

  .videoSection {
    margin-top: 50px !important;
  }

  .video-frame {
    width: 100%;
    margin: 20px 0px;
  }

  .step-element {
    width: 100%;
    flex-direction: column-reverse !important;
  }

  .step-element img {
    width: 100%;
    margin-bottom: 20px;
  }

  .step-element-dialog {
    width: 100%;
  }

  .loveHollyDeskSection {
    padding: 20px;
  }

  .loveHollyDesk {
    flex-direction: column-reverse;
  }

  .reasons-element {
    font-size: 16px;
  }

  .steps-1-2-arrow,
  .steps-2-3-arrow,
  .steps-3-4-arrow {
    display: none;
  }

  /* ---------- PRICING ---------- */

  .plan-cards-container {
    width: 100%;
    flex-direction: column;
  }

  .plan-card {
    width: 100%;
  }

  .pricingFAQSection {
    max-width: 100% !important;
  }

  /* ---------- CALCULATOR ---------- */

  .section-calculator {
    flex-direction: column;
    margin-top: 50px !important;
  }

  .calculationInputs-desc {
    font-size: 16px;
    margin-bottom: 30px !important;
  }

  .calc-sreen-qrtr {
    padding: 15px;
  }

  .screen-title {
    font-size: 16px;
  }

  .screen-unit {
    font-size: 14px;
  }

  /* ---------- DEMO ---------- */

  .demo-banner-title {
    font-size: 22px;
  }

  .demo-banner-subtitle {
    font-size: 16px;
  }

  .demo-img-1 {
    left: 10px;
    height: 35px;
  }

  .demo-img-2 {
    right: 0px;
    height: 120px;
  }

  .demo-img-3 {
    left: 20px;
    height: 35px;
  }

  .demo-img-4 {
    right: 0px;
    height: 150px;
    bottom: -100px;
  }

  .benefits-title {
    font-size: 18px !important;
    font-weight: 400;
  }

  /* ---------- DEMO ---------- */

  .masonry-review {
    width: 300px;
  }
}

@media (min-width: 601px) and (max-width: 1023px) {
  /* ---------- GENERAL ---------- */
  .mainPage {
    padding-inline: 15px;
  }

  .section-title {
    font-size: 1.7rem;
  }

  #article-header {
    display: none;
  }

  /* ---------- SECTIONS WIDTH ---------- */
  .landingPage-bannerbuttons,
  .featuredIn,
  .featuredIn-logos,
  .digitalize-title,
  .trustedBy-section,
  .companies-logos,
  .review,
  .testimonials,
  .read-all-reviews-btn,
  .referral-mainHeader-2,
  .referral-form,
  .FAQs-section,
  .knowMoreSection,
  .bannerArticle-poster,
  .bannerArticle-content,
  .stepsSection-title,
  .reasons,
  .secondBanner-title,
  .pricingFAQSection,
  .calculationInputs,
  .calc-start-btn,
  .calc-screen,
  .plan-cards-container {
    width: 100% !important;
  }

  /* ---------- NAV BAR ---------- */

  .nav-bar {
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
  }

  .nav-bar-items,
  .nav-bar-actions {
    display: none;
  }

  .mobile-menu {
    display: block;
  }

  /* ---------- HOME ---------- */
  .landingPage-bannerHeadLine {
    font-size: 1.7rem;
  }

  .landingPage-bannerSubTitle {
    font-size: 1.2rem;
  }

  .featuredIn-logos {
    width: 75%;
  }

  .featuredIn-logos img {
    width: 150px;
  }

  .digitalize-title {
    width: 80%;
  }

  .digital-section-card img {
    height: 60px;
  }

  .digital-section-card h1 {
    font-size: 1.7rem;
  }

  .digital-section-card p {
    font-size: 1.2rem;
  }

  .feature-element {
    width: 96%;
    margin: 30px auto;
    border-radius: 20px;
  }

  .feature-element-dialog {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .feature-element img {
    height: auto;
    width: 45%;
  }

  /* ---------- HOW IT WORKS ---------- */
  .video-frame {
    width: 90%;
    margin: 50px 5%;
  }

  .stepsSection-title {
    width: 60%;
  }

  .step-element {
    width: 90%;
    border-radius: 30px;
  }

  .loveHollyDeskSection {
    padding: 30px;
  }

  .loveHollyDeskSection img {
    width: 45%;
  }

  .secondBanner-title {
    width: 90%;
  }

  .steps-1-2-arrow,
  .steps-3-4-arrow {
    margin-inline-start: 40%;
    height: 10%;
  }

  .steps-2-3-arrow {
    height: 8%;
    top: 52%;
    left: 10%;
  }

  /* ---------- PRICING ---------- */

  .plan-card {
    width: 32%;
    padding: 25px 20px;
    margin: 20px auto;
  }

  .plan-price {
    font-size: 35px;
  }

  .plan-billTime {
    font-size: 20px;
  }

  .pricingFAQSection {
    max-width: 90% !important;
  }

  /* ---------- BLOG ---------- */

  .bannerArticle {
    flex-direction: column;
    overflow: auto;
    aspect-ratio: unset;
  }

  .article-card {
    width: 32%;
    margin-inline: 0.66%;
    padding: 5px;
  }

  .blog-form {
    width: 100%;
    border-radius: 20px;
  }

  .blog-form-line1 {
    font-size: 20px;
  }

  .blog-form-line2 {
    font-size: 24px;
  }

  .blog-form-inputGroup {
    width: 100%;
  }

  .article-content {
    width: 85%;
  }
}

@media (min-width: 1024px) and (max-width: 1300px) {
  /* ---------- GENERAL ---------- */
  .section-title {
    font-size: 2rem;
  }

  /* ---------- HOME ---------- */
  .landingPage-bannerHeadLine {
    font-size: 2rem;
  }

  .featuredIn-logos img {
    width: 150px;
    height: auto;
  }

  .digitalize-title {
    width: 80%;
  }

  .digital-section-card img {
    height: 60px;
  }

  .digital-section-card h1 {
    font-size: 1.7rem;
  }

  .digital-section-card p {
    font-size: 1.2rem;
  }

  .feature-element {
    width: 90%;
    margin: 30px auto;
    border-radius: 20px;
  }

  /* ---------- HOW IT WORKS ---------- */
  .video-frame {
    width: 90%;
    margin: 50px 5%;
  }

  .stepsSection-title {
    width: 60%;
  }

  .step-element {
    width: 90%;
    border-radius: 30px;
  }

  .loveHollyDeskSection {
    padding: 50px;
  }

  .secondBanner-title {
    width: 90%;
  }

  .steps-1-2-arrow {
    top: 23%;
  }

  .steps-1-2-arrow,
  .steps-3-4-arrow {
    margin-inline-start: 40%;
  }

  .steps-2-3-arrow {
    top: 50%;
    left: 10%;
  }

  /* ---------- PRICING ---------- */

  .plan-cards-container {
    width: 100%;
  }

  .plan-card {
    width: 32%;
    padding: 25px 20px;
    margin: 20px auto;
  }

  /* ---------- BLOG ---------- */

  .blog-form,
  .blog-form-inputGroup {
    width: 90%;
  }
}
